.custom-table {
    display: grid;
    width: 100%;
    height: auto;
    margin: 0 auto;
    overflow-x: auto;
}

.table-row {
    display: flex;
    width: 100%;
    height: 50px;
    color: 000;
    border-top: none;
}

.table-header {
    display: flex;
    min-width: max-content;
    height: 50px;
    text-transform: uppercase;
    background-color: var(--secondary);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.table-header-box {
    flex-grow: 1;
    flex-basis: 0;
    color: #000;
    height: 100%;
    align-items: center;
    display: flex;
    padding-left: 10px;
    font-weight: 600;
    min-width: 120px;
}

.table-header-row {
    width: 100%;
    display: flex;
}

@media only screen and (max-width: 500px) {
    .table-header-row {
        display: inline-flex;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
}

.table-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 0 !important;
}

.for-each-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    color: #18213d;
    background-color: #fff;
    border: 1px solid #dae7ff;
}

.table-body-box {
    flex-grow: 1;
    flex-basis: 0;
    min-width: 120px;
}

.table-container {
    width: 100%;
    margin: 20px 0 10px 0;
    border-radius: 4px;
    max-width: 100%;
}

.table-body {
    min-width: fit-content;
}

.table-row:hover {
    background-color: #f0f5ff;
}

.table-body-box {
    align-items: center;
    display: flex;
    height: 100%;
    padding-left: 10px;
}

.no-transactions {
    margin: 20px auto;
    color: #ffffff;
    text-align: center;
    font-size: 20px;

    &.light {
        color: #0e152a;
    }
}

.status-wrapper {
    border-radius: 0.3rem;
    width: 80%;
    line-height: 2rem;
    display: flex;
    align-items: center;
    font-size: 12px;
    text-align: center;
    max-width: 90px;
    min-width: 75px;

    .mat-icon {
        height: 12px;
        width: 15px;
        font-size: 12px;
        padding-left: 5px;
    }
}

.done {
    background: var(--green-light);
    color: var(--green-dark);
}

.cancelled {
    background: var(--red-light);
    color: var(--red-dark);
}

.pending {
    background: var(--secondary);
}

.icon-container {
    width: 100%;
    text-align: left;
    padding: 0.5rem 0.5rem;
    cursor: pointer;
    color: var(--primary);
}

.hover-pointer {
    cursor: pointer;
}

.mat-header-cell {
    background-color: var(--secondary);
    border-bottom: 0;
}

.cancel-cell {
    max-width: 70px;
}

.center {
    text-align: center;
}

.table-container {
    border-radius: 8px;
    overflow: hidden;
    font-family: var(--primary-font);
}
