.navigation {
    margin: 15px 0 0 60px;
    display: inline-block;

    &-elements {
        padding: 5px 10px;
        margin: 2px 0;
        border-radius: 8px;
        cursor: pointer;
    }

    &-elements:hover {
        background-color: var(--primary);
        color: #fff;
        animation: fadeItNav 0.3s ease-in-out;
    }

    &-element:active {
        background-color: rgba(0, 0, 0, 0);
        color: #fff;
    }
}

.navigation-tabletView {
    display: none;
}

.smaller {
    padding: 6px 0;
    font-size: 12px;
}

.larger {
    font-size: larger;
}

.user-info {
    padding: 0 10px;
}

.overlay-panel__panel-tabs {
    display: flex;
}

.tasks {
    display: flex;

    &-items {
        padding: 10px 15px;
        border: 2px solid var(--primary);
        color: var(--primary);
        border-radius: 8px;
        float: left;
        margin-right: 20px;
        margin-bottom: 20px;
    }

    &-items:hover {
        background-color: var(--primary);
        color: #fff;
        animation: fadeIt 0.3s ease-in-out;

        svg {
            //fill: #fff;
            stroke: #fff;

            path {
                //fill: #fff;
                stroke: #fff;
            }
        }
    }
}

@media only screen and (max-width: 500px) {
    .tasks {
        display: inline-block;
        height: auto;
        margin-bottom: 20px;
    }
}

.tasks-items {
    cursor: pointer;
}

.form-wrapper {
    &.balance {
        width: 30%;
        height: fit-content;
    }

    &.balance-graph {
        width: 60%;
        box-shadow: none;
        padding-top: 0;
    }

    .tasks-items {
        margin: 0;
        text-align: center;
        width: 100%;
    }
}

.navigationButtonActive {
    background-color: var(--primary);
    color: #fff;
    padding: 5px 20px;
    margin: 1px 0;
    border-radius: 8px;
}

.content {
    width: 75%;
}

.data-item {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    font-weight: bold;
    font-size: 16px;

    // &.remark {
    //     max-width: 1000px;
    //     margin: auto;
    // }
}

.data-wrapper {
    display: grid;
    font-size: 14px;
    row-gap: 2.5rem;
}

.data-wrapper-narrow {
    display: grid;
    font-size: 14px;
    row-gap: 0.5rem;
}

.personal-data-wrapper {
    max-width: 1000px;
    margin: auto;
}

.data-value {
    font-weight: normal;
    font-size: 16px;
}

.six-items-grid {
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 0.5rem;
}

.five-items-grid {
    grid-template-columns: repeat(5, 1fr);
}

.four-items-grid {
    grid-template-columns: repeat(4, 1fr);
}

.three-items-grid {
    grid-template-columns: repeat(3, 1fr);
}

.btn-rounded {
    background: var(--primary);
    border-radius: 4px;
    border: none;
    padding: 6px 24px;
    font-size: 20px;
    display: block;
    color: white;
    width: 250px;
    text-transform: uppercase;
    margin: auto;
}
