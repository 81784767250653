// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$swarm-primary: mat.define-palette(mat.$indigo-palette);
$swarm-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$swarm-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$swarm-theme: mat.define-light-theme(
    (
        color: (
            primary: $swarm-primary,
            accent: $swarm-accent,
            warn: $swarm-warn,
        ),
    )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($swarm-theme);

@import 'src/assets/styles/grid.scss';
@import 'src/assets/styles/common.scss';
@import 'src/assets/styles/vars.scss';
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500;600&display=swap');

@import '~swiper/swiper';
@import '~swiper/components/pagination/pagination';
@import '~swiper/components/navigation/navigation';
@import 'app/shared/styles/_table.scss';
@import 'app/shared/styles/_brand.scss';
@import 'app/shared/styles/_common.scss';
@import 'app/shared/styles/_forms.scss';
@import 'app/shared/styles/_overlay-panel.scss';

.swiper-container {
    overflow: visible;
}
.swiper-pagination-bullet {
    margin-right: 8px;
    background: #23315c;
    opacity: 1;
    &-active {
        background: #4c68a6;
        opacity: 1;

        &.light {
            background: #2a3c73;
        }
    }
    &:last-child {
        margin-right: 0;
    }
    &.light {
        background: #bcc3d0;
    }
}

html,
body {
    //min-height: 100vh;
    height: auto;
}
body {
    margin: 0;
    font-family: var(--primary-font), Roboto, 'Helvetica Neue', sans-serif;
    font-feature-settings: 'liga' off;
}
p {
    margin: 0;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

.mat-form-field-appearance-outline {
    .mat-form-field-outline-thick {
        color: var(--primary);
    }
    &.mat-focused {
        .mat-form-field-outline-thick {
            color: var(--primary);
        }
    }
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
    bottom: 0.25em;
}

.mat-option.mat-selected:not {
    color: var(--primary);
}
.mat-ripple-element {
    color: var(--primary);
}
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: var(--primary);
}
.mat-form-field-appearance-standard .mat-form-field-underline {
    background-color: #fff;
}
.mat-form-field-ripple {
    background-color: #fff;
}
.mat-form-field.mat-focused .mat-form-field-ripple {
    background-color: #fff;
}
.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
    background: var(--primary);
}
.mat-form-field-appearance-outline:not(.mat-form-field-disabled) .mat-form-field-flex:hover .mat-form-field-outline {
    color: #3b7cf5;
}
.mat-form-field-appearance-outline:not(.mat-form-field-disabled) .mat-form-field-flex:visited .mat-form-field-outline {
    color: var(--primary);
}
.mat-form-field.mat-focused .mat-form-field-label {
    color: var(--primary);
}
.mat-form-field-appearance-outline .mat-form-field-label {
    color: #545454;
}
.mat-form-field-label:not(.mat-form-field-empty) {
    color: var(--primary);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
    background-color: #fff;
}
.mat-select-disabled .mat-select-value {
    color: #000 !important;
}
.input-container {
    display: flex; /* Enables flexbox layout */
    align-items: center; /* Vertically centers the items in the container */
    justify-content: flex-end; /* Aligns children (input and span) to the right */
    width: 100%; /* Ensures the container takes full width of its parent */
}

.input-suffix {
    margin-left: auto; /* Pushes the suffix to the far right */
    white-space: nowrap; /* Prevents the suffix from wrapping */
}
